import { notify } from "Utilities/Notifications/Notify";
import axios from "axios";
import fileDownload from "js-file-download";
import log from "loglevel";

/////////////// -------- VERIFICATION --------   ///////////////

export const verifyAccount = async (data) => {
  try {
    const response = await axios.post("/user/verify/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.data?.msg) {
      notify(err?.response?.data?.msg, "error");
    } else {
      notify(err.message, "error");
    }
    return err?.response?.data;
  }
};

export const verifyMobile = async (data) => {
  try {
    const response = await axios.post("/user/verify_mobile/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return false;
  }
};

/////////////// -------- OTP --------   ///////////////

export const sendResetOtp = async (data) => {
  try {
    const response = await axios.post("/user/send_reset_otp/", data);
    if (response.status === 200) {
      log.log(response);
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const sendOtp = async (data) => {
  try {
    const response = await axios.post("/user/send_otp/", data);
    if (response.status === 200) {
      log.log(response);
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const otpLogin = async (data) => {
  try {
    const response = await axios.post("/user/login_otp/", data);
    if (response.status === 200) {
      log.log(response);
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

/////////////// -------- RESET PASSWORD --------   ///////////////

export const reset_password = async (data) => {
  try {
    const response = await axios.post("/user/reset_password/", data);
    return response.data;
  } catch (err) {
    return err?.response?.data;
  }
};

/////////////// -------- USERS -------- ///////////////

export const fetchUsers = async () => {
  try {
    const response = await axios.get(`/user/rest/users/`);
    log.log("fetchUsers Response", response);
    return response;
  } catch (err) {
    log.log("fetchUsers Error", err);
    return err;
  }
};

export const fetchUser = async (id) => {
  try {
    const response = await axios.get(`/user/rest/users/${id}/`);
    if (response) {
      log.log("fetchUser Response", response);
      return response;
    }
  } catch (err) {
    log.log("fetchUser Error", err);
    return err;
  }
};

export const addUser = async (data) => {
  try {
    log.log(data);
    const response = await axios.post(`/user/rest/users/`, data);
    if (response) {
      log.log("addUser Response", response);
      return response;
    }
  } catch (err) {
    log.log("addUser Error", err);
    return err;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.patch(`/user/rest/users/${id}/`, data);
    if (response) {
      log.log("updateUser Response", response);
      return response;
    }
  } catch (err) {
    log.log("updateUser Error", err);
    return err;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`/user/rest/users/${id}/`);
    if (response) {
      log.log("deleteUser Response", response);
      return true;
    }
  } catch (err) {
    log.log("deleteUser Error", err);
    return err;
  }
};

/////////////// -------- FILTERS -------- ///////////////

export const fetchLanguagesFormatTypes = async () => {
  try {
    const response = await axios.get(
      `/program/rest/shows/languages_format_types/`
    );
    if (response.status === 200) {
      log.log("Filters Response", response);
      return response.data;
    }
  } catch (err) {
    log.log("Filters Error", err);
    return err;
  }
};

/////////////// -------- THEATRE -------- ///////////////

export const fetchClientTheatres = async (clientId) => {
  try {
    const response = await axios.get(
      `/theatre/rest/theatre/?client=${clientId}`
    );
    if (response.status === 200) {
      log.log("Theatres Response", response);
      return response;
    }
  } catch (err) {
    log.log("Theatres Error", err);
    return err;
  }
};

/////////////// -------- CONTACT -------- ///////////////

export const addContact = async (data) => {
  try {
    log.log(data);
    const response = await axios.post(`/support/rest/contacts/`, data);
    if (response) {
      log.log("addContact Response", response);
      return response;
    }
  } catch (err) {
    log.log("addContact Error", err);
    return err;
  }
};

/////////////// -------- PROGRAM -------- ///////////////

export const fetchTrendingPrograms = async (clientId) => {
  try {
    const response = await axios.post(
      `/program/rest/client-programs/trending/`,
      { client: clientId }
    );
    if (response) {
      log.log("Trending Programs Response", response);
      return response;
    }
  } catch (err) {
    log.error("Trending Programs Error", err);
    return err;
  }
};

export const fetchClientProgram = async (clientProgramId, navigate) => {
  try {
    const response = await axios.get(
      `/program/rest/client-programs/${clientProgramId}/`
    );
    if (response) {
      log.log("Client Program Response", response);
      return response;
    }
  } catch (err) {
    log.error("Client Programs Error", err);
    if (err.response?.status === 404) {
      if (typeof navigate === "function") {
        navigate("/");
      }
    }
    return err;
  }
};
 
export const fetchAllPrograms = async (clientId) => {
  try {
    const response = await axios.post(
      `/program/rest/client-programs/all_movies/`,
      { client: clientId }
    );
    if (response) {
      log.log("Trending Programs Response", response);
      return response;
    }
  } catch (err) {
    log.log("Trending Programs Error", err);
    return err;
  }
};

export const fetchComingSoonMovies = async (clientId) => {
  try {
    const response = await axios.post(
      `/program/rest/client-programs/coming_soon/`,
      { client: clientId }
    );
    if (response) {
      log.log("Trending Programs Response", response);
      return response;
    }
  } catch (err) {
    log.log("Trending Programs Error", err);
    return err;
  }
};

export const fetchLatestMovies = async (clientId) => {
  try {
    const response = await axios.post(`/program/rest/client-programs/latest/`, {
      client: clientId,
    });
    if (response) {
      log.log("Trending Programs Response", response);
      return response;
    }
  } catch (err) {
    log.log("Trending Programs Error", err);
    return err;
  }
};

/////////////// -------- SHOW -------- ///////////////

export const fetchSelectedDateShows = async (
  clientId,
  clientProgramId,
  date
) => {
  try {
    const response = await axios.post(`/program/rest/shows/datewise_shows/`, {
      client: clientId,
      client_program: clientProgramId,
      date: date,
    });
    if (response) {
      log.log("Selected Date Shows Response", response);
      return response;
    }
  } catch (err) {
    log.log("Selected Date Shows Error", err);
    return err;
  }
};

export const fetchSelectedTheatreDateShows = async (
  clientId,
  theatreId,
  date,
  languages,
  formatTypes
) => {
  try {
    const response = await axios.post(
      `/program/rest/shows/theatre_date_wise_movies/`,
      {
        client: clientId,
        theatre: theatreId,
        date: date,
        languages: languages,
        format_types: formatTypes,
      }
    );
    if (response) {
      log.log("Selected Theatre Date Shows Response", response);
      return response;
    }
  } catch (err) {
    log.log("Selected Theatre Date Shows Error", err);
    return err;
  }
};

export const fetchShow = async (showId) => {
  try {
    const response = await axios.get(`/program/rest/shows/${showId}/`);
    if (response) {
      log.log("Show Response", response);
      return response;
    }
  } catch (err) {
    log.log("Show Error", err);
    return err;
  }
};

export const fetchShowSeats = async (showId) => {
  try {
    const response = await axios.post(`/program/rest/shows/${showId}/seats/`, {
      channel_code: "WEB",
    });
    if (response) {
      log.log("Show seats Response", response);
      return response;
    }
  } catch (err) {
    log.log("Show seats Error", err);
    return err;
  }
};

export const fetchShowSeatTypes = async (showId) => {
  try {
    const response = await axios.post(
      `/program/rest/shows/${showId}/seat_types/`
    );
    if (response) {
      log.log("Seat types Response", response);
      return response;
    }
  } catch (err) {
    log.log("Seat types Error", err);
    return err;
  }
};

export const lockSeats = async (data) => {
  try {
    const response = await axios.post(`/program/lock_seats/`, data);
    if (response.status === 200) {
      log.log("Lock seats Response", response);
      return true;
    }
  } catch (err) {
    notify(
      "Some of the seats you selected are already booked! Please select different seats.",
      "error"
    );
    return false;
  }
};

export const unlockSeats = async (data) => {
  try {
    const response = await axios.post(`/program/unlock_seats/`, data);
    if (response.status === 200) {
      log.log("Lock seats Response", response);
      return true;
    }
  } catch (err) {
    notify(
      "Some of the seats you selected are already booked! Please select different seats.",
      "error"
    );
    return false;
  }
};

/////////////// -------- CATEGORY & FOOD -------- ///////////////

export const fetchCategories = async (theatreId) => {
  try {
    const response = await axios.get(`/food/categories/?theatre=${theatreId}`);
    if (response) {
      log.log("Category Response", response);
      return response;
    }
  } catch (err) {
    log.log("Category Error", err);
    return err;
  }
};

export const fetchFoodOfCategory = async (theatreId, categoryId) => {
  try {
    // let search_params = ""
    // if (categoryId !== "") {
    //   search_params += `?category=${categoryId}`
    // } else {
    //   search_params += `?category__theatre=${theatreId}`
    // }
    const response = await axios.post(`/food/foods/get_food_by_category/`, {
      theatre_id: theatreId,
      category: categoryId,
    });
    if (response) {
      log.log("Food Response", response);
      return response;
    }
  } catch (err) {
    log.log("Food Error", err);
    return err;
  }
};

export const fetchConvenienceFee = async () => {
  try {
      const response = await axios.get('/user/rest/channel-settings/convenience_fee/?channel_code=WEB');
      console.log(response);
      return response.data;
  } catch (err) {
      console.error(err);
      throw new Error("Error while fetching convenience fee type choices");
  }
}

/////////////// -------- TAXES -------- ///////////////

export const getTaxes = async (theatreId) => {
  try {
    const response = await axios.post(`/theatre/rest/theatretax/by_theatre/`, {
      theatre_id: theatreId,
    });
    if (response.status === 200) {
      log.log("Lock seats Response", response);
      return response.data;
    }
  } catch (err) {
    notify(
      "Some of the seats you selected are already booked! Please select different seats.",
      "error"
    );
    return false;
  }
};

/////////////// -------- COUPONS -------- ///////////////

export const checkSeatCouponValidity = async (data) => {
  try {
    const response = await axios.post(
      `/coupon/rest/seat-coupons/check_validity/`,
      data
    );
    if (response.status === 200) {
      notify("MUR " + response.data.value + " Discount applied!", "success");
      return response;
    }
  } catch (err) {
    // if (Array.isArray(err?.response?.data?.msg)) {
    //   notify(err?.response?.data?.msg[0], "error");
    // } else {
    //   notify(err?.response?.data?.msg, "error");
    // }
    notify("Coupon not found!", "error");
    return err;
  }
};

export const checkFoodCouponValidity = async (data) => {
  try {
    const response = await axios.post(
      `/coupon/rest/food-coupons/check_validity/`,
      data
    );
    if (response.status === 200) {
      notify("MUR " + response.data.value + " Discount applied!", "success");
      return response;
    }
  } catch (err) {
    // if (Array.isArray(err?.response?.data?.msg)) {
    //   notify(err?.response?.data?.msg[0], "error");
    // } else {
    //   notify(err?.response?.data?.msg, "error");
    // }
    notify("Coupon not found!", "error");
  }
};

/////////////// -------- ORDERS -------- ///////////////

export const createOrder = async (data) => {
  try {
    const response = await axios.post(`/order/rest/orders/create_order/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    if (Array.isArray(err?.response?.data?.msg)) {
      if (err?.response?.data?.msg[0]) {
        notify(err?.response?.data?.msg[0], "error");
      } else {
        notify("Order creation failed!", "error");
      }
    } else {
      if (err?.response?.data?.msg) {
        notify(err?.response?.data?.msg, "error");
      } else {
        notify("Failed to create the order!", "error");
      }
    }
  }
};

export const getIndividualTicketOrder = async (orderId) => {
  try {
    const response = await axios.get(`/order/rest/orders/${orderId}/`);
    return response.data;
  } catch (err) {
    throw new Error(err)
  }
};

export const getTicketOrder = async (userId) => {
  try {
    const response = await axios.get(`/order/rest/orders/?user=${userId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      notify("There was an error loading orders", "error");
    }
  } catch (err) {
    if (Array.isArray(err?.response?.data?.msg)) {
      notify(err?.response?.data?.msg[0], "error");
    } else {
      notify(err?.response?.data?.msg, "error");
    }
  }
};

export const getAnonymousTicketOrder = async (orderId) => {
  try {
    const response = await axios.get(`/order/anonymous-order/?id=${orderId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      notify("There was an error loading orders", "error");
    }
  } catch (err) {
    if (Array.isArray(err?.response?.data?.msg)) {
      notify(err?.response?.data?.msg[0], "error");
    } else {
      notify(err?.response?.data?.msg, "error");
    }
  }
};

export const getTicketPdf = async (orderId) => {
  try {
    const response = await axios.get(
      `/order/rest/orders/${orderId}/tickets_pdf/`,
      {
        responseType: "blob",
      }
    );
    log.log(response)
    fileDownload(
      new Blob([response.data], { type: "application/pdf" }),
      `Tickets_${orderId}.pdf`
    );
    return true;
  } catch (err) {
    log.log(err);
  }
};

export const getFoodOrder = async (userId) => {
  try {
    const response = await axios.get(`/order/rest/food_orders/?user=${userId}`);
    if (response.status === 200) {
      return response.data;
    } else {
      notify("There was an error loading orders", "error");
    }
  } catch (err) {
    if (Array.isArray(err?.response?.data?.msg)) {
      notify(err?.response?.data?.msg[0], "error");
    } else {
      notify(err?.response?.data?.msg, "error");
    }
  }
};

export const getFoodPdf = async (foodOrderId) => {
  try {
    const response = await axios.get(
      `/order/rest/food_orders/${foodOrderId}/food_pdf/`,
      {
        responseType: "blob",
      }
    );
    log.log(response)
    fileDownload(
      new Blob([response.data], { type: "application/pdf" }),
      `Food Order ${foodOrderId}.pdf`
    );
    return true;
  } catch (err) {
    //consolelog(err);
    notify("Failed to download pdf!", "error");
    return err;
  }
};

export const getPaymentInfo = async (order_id, payment_id, theatre_id) => {
  try {
    const response = await axios.post(
      `/order/rest/payments/get_payment_info/`,
      { order_id, payment_id, theatre_id }
    );
    return response.data;
  } catch (er) {
    console.error(er);
    if (er?.response?.data) {
      return er.response.data;
    } else {
      return er?.message;
    }
  }
};

export const getMytPaymentInfo = async (payment_id, theatre_id) => {
  try {
    const response = await axios.post(
      `/order/rest/payments/get_myt_payment_info/`,
      { payment_id, theatre_id }
    );
    return response.data;
  } catch (er) {
    console.error(er);
    if (er?.response?.data) {
      return er.response.data;
    } else {
      return er?.message;
    }
  }
};

export const fetchTheatreURLFromShowId = async (show_id) => {
  try {
    const response = await axios.post(
      `/program/rest/shows/theatre_details/`, { show_id: show_id }
    );
    return response;
  } catch (er) {
    console.error(er);
    if (er?.response?.data) {
      return er.response.data;
    } else {
      return er?.message;
    }
  }
}

export const fetchClientChannelSettings = async (client_id, theatre_id, channel_code) => {
  try {
    const response = await axios.post(
      `/user/rest/channel-settings/get_client_channel_setting/`, { client_id, theatre_id, channel_code }
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}