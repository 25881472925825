import React from 'react';
import { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

// Define allowed countries
let allowedCountries = ["MU"]; // Example allowed countries
try {
    let envCountries = process.env.REACT_APP_ALLOWED_COUNTRIES;
    envCountries = envCountries.split(",").map(r => r.trim());
    allowedCountries = envCountries;
} catch (err) {
    console.error(err)
}

const countryCheckApi = "https://country.prosoft.mu";

export const getUserCountry = async () => {
    const cachedCountry = sessionStorage.getItem('userCountry');
    if (cachedCountry) {
        let country = JSON.parse(cachedCountry);
        return { country: country, isAllowed: allowedCountries.includes(country) };
    }

    const res = await fetch(countryCheckApi);
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    const data = await res.json();

    sessionStorage.setItem('userCountry', JSON.stringify(data?.country));

    return { country: data?.country, isAllowed: allowedCountries.includes(data?.country) };
}



const GeoBlockedRoute = ({ children, showModal = true }) => {
    const [country, setCountry] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isCancelled = false;

        async function fetchAPI() {
            setIsLoading(true);
            try {
                const { country: userCountry } = await getUserCountry();
                if (userCountry && !isCancelled) {
                    setCountry(userCountry);
                }
            } catch (err) {
                setError(true);
            } finally {
                if (!isCancelled) {
                    setIsLoading(false);
                }
            }
        }

        fetchAPI();

        return () => {
            isCancelled = true;
        };
    }, []);


    let defaultPage = <><div
        className="bg-slate user-select-none pb-1"
        style={{ minHeight: "85vh" }}
    >
        <Modal
            dialogClassName="d-flex align-items-center h-100"
            contentClassName="bg-slate border-0"
            show={true}
        >
            <Modal.Header
                closeButton={false}
                className="d-flex border-0 d-flex justify-content-center align-items-center"
            >
                <Modal.Title className="text-white">
                    We are sorry...
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3 d-flex gap-3 flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-between w-100">
                    <p className="text-center text-white">Our movies are available to book <strong>exclusively</strong> from Mauritius.<br></br><br></br>{!error ? "Detected Country: " + country : "Error detecting country"}</p>
                </div>
            </Modal.Body>
        </Modal></div></>;
    // Handle error state
    if (error) {
        return defaultPage;
    }

    // Check if the user is from an allowed country
    if (!allowedCountries.includes(country)) {
        if (showModal) {
            return defaultPage;
        }

        return <></>
    }

    // If the user is allowed, render the child component (protected content)
    return children;
};

export default GeoBlockedRoute;